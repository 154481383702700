import axios from "axios";

export const fetchApi = async (search: string[]) => {
    const config = {
        method: 'get',
        url: 'https://api.apihost.ru/v1/content/remote?url='+ search[1],
        headers: { }
    };
    const res = await axios(config);
    const params = {}

    if(res.status === 200) {
        const {player, content} = await res.data.data
        const {type_player, main_color, border_radius, border_color, background_color} = player
        const {mp3} = content;

        params["type_player"] = type_player;
        params["main_color"] = main_color;
        params["border_radius"] = border_radius;
        params["border_color"] = border_color
        params["background_color"] = background_color;
        params["file"] = mp3;
    }

    return params;
}


export const fetchRenderPlayer = async (search: string[]) => {
    await axios.post(`https://api.apihost.ru/v1/analytic/view?url=${search[1]}`)
}

export const fetchByPlaying = async (search: string) => {
    await axios.post(`https://api.apihost.ru/v1/analytic/click?url=${search}`)
}

export const fetchByEnd = async (search: string) => {
    await axios.post(`https://api.apihost.ru/v1/analytic/hard?url=${search}`)
}
