import React, {useEffect, useState} from 'react';
import {AudioPlayer} from "./audioPlayer";
import axios from "axios";
import {fetchApi, fetchRenderPlayer} from "./api";

//iFrame.src = `${BASE_URL_PLAYER}?file=${mp3}&type_player=${type_player}&main_color=${main_color}&border_radius=${border_radius}&border_color=${border_color}&background_color=${background_color}`
interface IState {
    baseUrl: string;
    type_player?: string;
    main_color?: string;
    border_color?: string;
    border_radius?: string;
    background_color?: string;
    file?: string;
}

const App = () => {
    const [params, setParams] = useState<IState | {}>({});
    const [path, setPath] = useState<string>('')
    useEffect(() => {
        const search = window.location.search.split('=');
        setPath(search[1])
        fetchApi(search).then(params => setParams(params))
        fetchRenderPlayer(search);
    }, []);

    return Object.keys(params).length > 0 ? (
        <AudioPlayer
            path={path}
            type_player={"type_player" in params ? params.type_player : 'first'}
            main_color={"main_color" in params ? params.main_color : '#FFFFFF'}
            border_color={"border_color" in params ? params.border_color : '#FFFFFF'}
            border_radius={"border_radius" in params ? params.border_radius : '0'}
            background_color={"background_color" in params ? params.background_color : '#FFFFFF'}
            file={`https://api.apihost.ru/v1/player/remote?file=${"file" in params ? params.file : ''}`}
        />
    ) : <></>;
};

export default App;
