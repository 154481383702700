export const transformTime = (time:number ) => {
    time = Math.floor(time);
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    // @ts-ignore
    let minutesVal: string = minutes;
    // @ts-ignore
    let secondsVal: string = seconds;
    if (minutes < 10) {
        minutesVal = '0' + minutes;
    }
    if (seconds < 10) {
        secondsVal = '0' + seconds;
    }
    return  minutesVal + ':' + secondsVal;
};
