import React from 'react';
import {IDataProps} from '../types';
import {transformTime} from '../helpers/transformTime';


export const CurrentTime = ({time}: IDataProps) => {

    return (
        <span className={'main-time'}>{transformTime(time)}</span>
    );
};

