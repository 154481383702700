import React from 'react';
import {getTrackBackground, Range} from 'react-range';

interface IProgressProps {
    handleVolume: (values: number[]) => void;
    max: number;
    min: number;
    volume: number;
    color: string
}

export const ProgressBar = (
    {handleVolume, volume, min, max, color}: IProgressProps
) => {

    return (
        <div className={'progress main-progress'}>
            <Range
                onChange={handleVolume}
                values={[volume]}
                step={0.1}
                max={Math.round(max)}
                min={min}
                renderTrack={({props, children}) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '6px',
                            width: '150px',
                            backgroundColor: 'rgba(33,33,33,0.25)',
                            position: 'relative',
                            borderRadius: '5px',
                            background: getTrackBackground({
                                values: [volume],
                                colors: [color, '#ccc'],
                                min: min,
                                max: max
                            }),
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({props}) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '15px',
                            width: '15px',
                            borderRadius: '50%',
                            backgroundColor: color,
                            position: 'absolute'
                        }}
                    />
                )}/>
        </div>
    );
};
