import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Controls, CurrentTime, Duration, ProgressBar, Volume} from './components';

import './styles/main.css';
import {IAudioPlayer} from "./types";
import {fetchByEnd, fetchByPlaying} from "../api";
let step:number = 0;

function Element(type_player: string) : JSX.Element | string {

    return type_player === 'watermark' ? <a className="watermark" target="_blank" rel="noreferrer" href="https://audio.apihost.ru" >Озвученно Apihost</a>: '';
}

export const AudioPlayer = (
    {
        type_player,
        main_color,
        background_color,
        border_color,
        border_radius,
        file,
        path
    }: IAudioPlayer
) => {
    const player = useRef<HTMLAudioElement>(null);

    const [audio, setAudio] = useState<HTMLAudioElement>();
    const [showPlayer, setShowPLayer] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [volume, setVolume] = useState<number>(1);

    useEffect(() => {
        if (player) {
            player.current.addEventListener('timeupdate', function () {
                const progress = this.currentTime;
                setProgress(progress);
                setCurrentTime(this.currentTime);
                if (Math.floor(this.currentTime) > Math.floor(this.duration) / 100 * 95) {
                    if(step === 0) {
                       fetchByEnd(path)
                        step ++;
                    }
                }
            });
        }
    }, [audio]);

    const handleProgressClick = (value:number) => {
        audio.currentTime = value;
        setProgress(value);
    };

    const handleInit = () => {
        setAudio(player.current);
        setShowPLayer(true);
    };

    const handleControl = async () => {
        if(audio.paused && currentTime === 0) {
            await fetchByPlaying(path)
        }
        !audio?.paused
            ? audio.pause()
            : await audio.play();
    };

    const renderCurrentTime = useMemo(
        () =>
            <CurrentTime time={currentTime}/>,
        [currentTime]);

    const renderDuration = useMemo(
        () =>
            <Duration
                time={audio?.duration ?? 0 - currentTime}
            />,
        [currentTime, audio]);

    return (
        <div className={`main-wrapper-audio-${type_player}`}
             style={{
                 backgroundColor: background_color,
                 border: `1px solid ${border_color}`,
                 borderRadius: `${border_radius}px`
             }}>
            <audio
                ref={player}
                src={file}
                style={{display: 'none'}}
                onCanPlay={handleInit}
            />
            <Controls
                handleControl={handleControl}
                isPaused={audio?.paused}
                main_color={main_color}
                type_player={type_player}
                border_color={border_color}
            />
            {showPlayer && (
                <div
                    className={`main-audio-${type_player}`}
                >
                    {renderCurrentTime}
                    <ProgressBar
                        volume={progress}
                        min={0}
                        max={audio?.duration}
                        handleVolume={values => handleProgressClick(values[0])}
                        color={main_color}
                    />
                    {renderDuration}
                </div>
            )}
            <Volume
                color={main_color}
                type_player={type_player}
                volume={volume}
                background_color={background_color}
                border_color={border_color}
                setVolume={(volume) => {
                    audio.volume = volume;
                    setVolume(volume);
                }}
            />

            {Element(type_player)}
        </div>
    );
};
