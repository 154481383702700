import React from 'react';
import {IIconProps} from './types';

export const VolumeMute = ({color = '#55606E'}:IIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 32 32">
            <g data-name="Layer 2" id="Layer_2">
                <path fill={color} d="M19.45,4.11a1,1,0,0,0-1,.09L10.67,10H3a1,1,0,0,0-1,1V21a1,1,0,0,0,1,1h7.67l7.73,5.8A1,1,0,0,0,20,27V5A1,1,0,0,0,19.45,4.11Z"/>
                <path fill={color} d="M27.41,16l2.29-2.29a1,1,0,0,0-1.41-1.41L26,14.59l-2.29-2.29a1,1,0,0,0-1.41,1.41L24.59,16l-2.29,2.29a1,1,0,1,0,1.41,1.41L26,17.41l2.29,2.29a1,1,0,0,0,1.41-1.41Z"/>
            </g>
        </svg>
    );
};
