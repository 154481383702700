import React from 'react';
import {IIconProps} from './types';

export const VolumeUp = ({color = '#55606E'}:IIconProps) => {
    return (
        <svg viewBox="0 0 32 32" width="24" height="24">
            <g data-name="Layer 2" id="Layer_2">
                <path fill={color} d="M19.45,4.11a1,1,0,0,0-1,.09L10.67,10H3a1,1,0,0,0-1,1V21a1,1,0,0,0,1,1h7.67l7.73,5.8A1,1,0,0,0,20,27V5A1,1,0,0,0,19.45,4.11Z"/>
                <path fill={color} d="M23,12a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V13A1,1,0,0,0,23,12Z"/>
                <path fill={color} d="M26,10a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V11A1,1,0,0,0,26,10Z"/>
                <path fill={color} d="M29,8a1,1,0,0,0-1,1V23a1,1,0,0,0,2,0V9A1,1,0,0,0,29,8Z"/>
            </g>
        </svg>
    );
};
