import React from 'react';
import {IIconProps} from './types';

export const Pause = ({color = '#55606E', type_player, border_color}: IIconProps) => {
    if (type_player === 'first') {
        return (<svg viewBox="0 0 34 34" width="30" height="30">
            <g data-name="Layer 2" id="Layer_2">
                <rect fill={color} height="28" rx="1" ry="1" width="11" x="2" y="2"/>
                <rect fill={color} height="28" rx="1" ry="1" width="11" x="19" y="2"/>
            </g>
        </svg>);
    } else if (type_player === 'second') {
        return (<svg version="1.1" id="Capa_1" x="0px" y="0px"
                     viewBox="0 0 60 60" width="35" height="35">
                <circle fill={color} cx="29" cy="29" r="29"/>
                <path fill={border_color} d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M27,46h-8V14h8V46z M41,46h-8V14h8V46z"/>
            </svg>
        );
    } else if (type_player === 'three') {

        return (<svg viewBox="0 0 34 34" width="22" height="22">
            <g data-name="Layer 2" id="Layer_2">
                <rect fill={color} height="28" rx="1" ry="1" width="11" x="2" y="2"/>
                <rect fill={color} height="28" rx="1" ry="1" width="11" x="19" y="2"/>
            </g>
        </svg>);
    }

    return (<svg viewBox="0 0 34 34" width="25" height="25">
        <g data-name="Layer 2" id="Layer_2">
            <rect fill={color} height="28" rx="1" ry="1" width="11" x="2" y="2"/>
            <rect fill={color} height="28" rx="1" ry="1" width="11" x="19" y="2"/>
        </g>
    </svg>);
};
