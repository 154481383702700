import React from 'react';
import {Pause, Play} from '../icons';

interface IControlProps {
    handleControl: () => void;
    type_player: string;
    main_color: string;
    border_color: string;
    isPaused: boolean
}

export const Controls = ({isPaused, handleControl, main_color, type_player, border_color}:IControlProps) => {
    return (
        <div className={`controls-${type_player} controls`} onClick={handleControl}>
            {
                isPaused
                    ? <Play color={main_color} type_player={type_player} border_color={border_color}/>
                    : <Pause color={main_color} type_player={type_player} border_color={border_color}/>
            }
        </div>
    );
};
